import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Columns, Column, Title } from "../../sdk/Layout";
import { Checkbox, Control } from "../../sdk/Form";
import { Button, Buttons, Icon } from "../../sdk/Button";
import Icons2 from "../Icons2";
import useInCart from "../../../blueberry/hooks/useInCart";
import useLoggedIn from "../../../blueberry/hooks/useLoggedIn";
import renderIf from "../../utils/renderIf";
import { useTranslation } from "../../../lang";
import Gallery from "../Gallery2";

import Product, {
	AddToCart,
	DetailProductTable,
	ProductMeta,
	getFair,
	Item,
	ProductTitle,
	AsociatedProducts,
} from "../../components/Product2";
import { Helmet } from "react-helmet";

const TitleExtended = styled(Title)`
	margin-top: 0;
`;

const ColsResponsive = styled(Columns)`
	flex-wrap: wrap;
`;
const ColumnProducts = styled(Column)`
	@media (max-width: 1215px) {
		width: 100%;
	}
`;

const Detail = styled.div`
	${Title} {
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
		@media (max-width: 980px) {
			font-size: 18px;
		}
	}

	table {
		font-size: 14px;
		background-color: transparent;
		width: 100%;
		border: solid 1px ${(props) => props.theme.borderColor};
		color: ${(props) => props.theme.primaryDark};
		@media (max-width: 980px) {
			font-size: 12px;
		}
		th {
			width: 1px;
			white-space: nowrap;
		}
		td,
		th {
			padding: 5px;
		}
		tr + tr th,
		tr + tr td {
			border-top: solid 1px ${(props) => props.theme.borderColor};
		}
		a {
			cursor: pointer;
		}
		.price_d {
			background-color: ${(props) => props.theme.grayLight};
		}
	}
`;

// ${ Item } {
//     padding: 10px;
//     h3 {
//         font - size: 12px;
//         line - height: 14px;
//     }
// }

const Btn = styled.div`
	width: 100%;
	padding-left: 10px;
`;

const Related = styled.div`
	width: 100%;
	max-width: 300px;
	${Item} {
		width: 48%;
		padding: 10px;
		margin-bottom: 1%;
		> div {
			padding-top: 10px;
			h3 {
				font-size: 12px;
			}
		}
	}
	@media (max-width: 1215px) {
		max-width: 100%;
		& ${Item} {
			width: 24%;
		}
	}
`;

const Description = styled.div`
	padding: 20px 0;
	p {
		font-size: 14px;
		color: #58585c;
		+ p {
			margin-top: 10px;
		}
	}
`;

const Topbar = styled.div`
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	${Button} {
		margin-bottom: 0;
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const NextPrevItem = styled.div`
	display: none;
	align-items: center;
	background-color: #f0f0f0;
	padding: 5px;
	cursor: pointer;
	svg {
		width: 15px;
		height: 15px;
		color: #959595;
	}
	img {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		&:last-child {
			margin-left: 5px;
		}
		&:first-child {
			margin-right: 5px;
		}
	}
`;
const ProducTitleWrap = styled.div`
	@media (max-width: 768px) {
		margin-right: -5px;
		margin-left: -5px;
		padding-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		h1 {
			margin-bottom: 0 !important;
			padding-bottom: 0 !important;
			padding-right: 5px;
			padding-left: 5px;
			line-height: 16px !important;
		}
		& ${NextPrevItem} {
			display: flex;
			&:first-child {
				border-radius: 0 25px 25px 0;
			}
			&:last-child {
				border-radius: 25px 0 0 25px;
			}
		}
	}
`;

let strings = {
	available: "Disponible",
	notAvailable: "No Disponible",
	fair: "Futuro",
};

function ProductDetails2({
	product,
	addToCart,
	removeItem,
	retrieveProduct,
	viewItem,
	handleNextProduct,
	asociatedProducts,
	handleLastProduct,
	backSlug,
	nextSlug,
	jsonSettings,
	backProduct,
	nextProduct,
	refetch,
	defaultQuantity,
}) {
	const [_t] = useTranslation();
	const isFair = getFair(product);
	const isLoggedIn = useLoggedIn();
	const [productAssociatedImage, setProductAssociatedImage] =
		React.useState(null);

	const inCart = useInCart(product ? product._id : null);
	const isInCart = inCart != null;

	React.useEffect(() => {
		if (product && retrieveProduct) retrieveProduct(product.slug);
	}, [product ? product.slug : null]);

	React.useEffect(() => {
		strings = {
			available: _t("available"),
			notAvailable: _t("notAvailable"),
			fair: _t("fair"),
			quickView: _t("quickView"),
		};
	}, []);

	const onKeyDown = (event) => {
		if (event.keyCode === 39) {
			handleNextProduct();
		} else if (event.keyCode === 37) {
			handleLastProduct();
		} else if (event.keyCode === 27) {
			// hideQuickView();
		}
	};
	const onMouseEnterAssociated = (url) => {
		setProductAssociatedImage(url);
	};
	const onMouseLeaveAssociated = (url) => {
		setProductAssociatedImage(null);
	};

	React.useEffect(() => {
		document.addEventListener("keydown", onKeyDown, false);
		return () => {
			document.removeEventListener("keydown", onKeyDown, false);
		};
	}, []);

	if (product == null) return null;
	return (
		<ColsResponsive>
			<Column>
				<Detail>
					<Columns>
						<Column>
							<Topbar>
								<div></div>
								<div>
									<Buttons className="">
										{renderIf(
											backSlug !== "" && backSlug != null,
										)(
											<Button
												small
												onClick={handleLastProduct}>
												<Icons2 icon="chevron-double-left"></Icons2>
											</Button>,
										)}
										{renderIf(
											nextSlug !== "" && nextSlug != null,
										)(
											<Button
												small
												onClick={handleNextProduct}>
												<Icons2 icon="chevron-double-right"></Icons2>
											</Button>,
										)}
									</Buttons>
								</div>
							</Topbar>

							<Gallery
								key={`maginigfy-${product._id}`}
								forceDisplay={productAssociatedImage}
								videosUrl={product.videosUrl}
								slug={
									(product.title ||
										product.title_eng ||
										product.title_pt) +
									" - " +
									product.sku
								}
								images={product.imagesUrl}
							/>
						</Column>
						<Column>
							<ProducTitleWrap>
								{backProduct ? (
									<NextPrevItem
										onClick={handleLastProduct}
										tabIndex="0"
										role="button">
										<Icons2 icon="chevron-left-light"></Icons2>
										<img
											src={
												backProduct &&
												backProduct.imagesUrl &&
												backProduct.imagesUrl.length > 0
													? backProduct.imagesUrl[0]
															.thumb
													: ""
											}
											alt={
												backProduct
													? backProduct.title
													: ""
											}
										/>
									</NextPrevItem>
								) : null}
								<ProductTitle
									size="4"
									title={product.title}
									title_eng={product.title_eng}
									title_pt={product.title_pt}
									slug={product.slug}
								/>
								{nextProduct ? (
									<NextPrevItem
										tabIndex="0"
										role="button"
										onClick={handleNextProduct}>
										<img
											src={
												nextProduct &&
												nextProduct.imagesUrl &&
												nextProduct.imagesUrl.length > 0
													? nextProduct.imagesUrl[0]
															.thumb
													: ""
											}
											alt={
												nextProduct
													? nextProduct.title
													: ""
											}
										/>
										<Icons2 icon="chevron-right-light"></Icons2>
									</NextPrevItem>
								) : null}
							</ProducTitleWrap>
							<ProductMeta
								product={product}
								isFair={isFair}
								isLoggedIn={isLoggedIn}
								jsonSettings={jsonSettings}
							/>
							<div>
								<AddToCart
									product={product}
									isInCart={isInCart}
									isFair={isFair}
									defaultQuantity={
										defaultQuantity
											? defaultQuantity
											: inCart
									}
									isLoggedIn={isLoggedIn}
									asociatedProducts={asociatedProducts}
									addToCart={addToCart}
									viewItem={viewItem}
									removeItem={removeItem}
								/>
							</div>
							<div>
								<DetailProductTable
									isFair={isFair}
									refetch={refetch}
									product={product}
									isLoggedIn={isLoggedIn}
									jsonSettings={jsonSettings}
								/>
							</div>

							<AsociatedProducts
								viewItem={viewItem}
								onMouseEnter={onMouseEnterAssociated}
								onMouseLeave={onMouseLeaveAssociated}
								asociatedProducts={asociatedProducts}
							/>
							{product.content ? (
								<Description
									dangerouslySetInnerHTML={{
										__html: product.content,
									}}
								/>
							) : null}
						</Column>
					</Columns>
				</Detail>
			</Column>
			{/* <ColumnProducts isNarrow isFull980>
                <Related>
                    {renderIf(
                        crossSell != null &&
                            crossSell.products &&
                            crossSell.products.length > 0
                    )(
                        <TitleExtended as="h3" size="6">
                            {_t("relatedProducts")}
                        </TitleExtended>
                    )}
                    {crossSell &&
                        crossSell.products.map((index) => (
                            <Product
                                product={index}
                                strings={strings}
                                key={index._id}
                                viewItem={viewItem}
                                addToCart={addToCart}
                            />
                        ))}
                </Related>
            </ColumnProducts> */}
		</ColsResponsive>
	);
}

ProductDetails2.propTypes = {
	viewItem: PropTypes.func.isRequired,
	addToCart: PropTypes.func.isRequired,
	product: PropTypes.object.isRequired,
};

export default React.memo(ProductDetails2);
