import React from "react";

export default function WithWidth(options) {
    const BaseComponent = React.PureComponent;
    return (WrappedComponent) =>
        class ReactBlueberryDataComponent extends BaseComponent {
            state = {
                width: 1200,
            };

            componentDidMount() {
                this.updateDimensions();
                if (typeof window !== "undefined") {
                    window.addEventListener("resize", this.updateDimensions);
                }
            }

            componentWillUnmount() {
                if (typeof window !== "undefined") {
                    window.removeEventListener("resize", this.updateDimensions);
                }
            }

            updateDimensions = () => {
                if (typeof window !== "undefined")
                    this.setState({
                        width: Math.max(
                            document.documentElement.clientWidth,
                            window.innerWidth || 0
                        ),
                    });
            };

            render() {
                const { width } = this.state;
                return <WrappedComponent width={width} {...this.props} />;
            }
        };
}
