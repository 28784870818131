import React from "react";
import ReactImageMagnify from "react-image-magnify";
import styled from "styled-components";
import IconLibrary from "../../Icons2";
import { Button } from "../../../sdk/Button";
import useWindowSize from "../../../hooks/useWindowSize";

const Container = styled.div`
	.image-object {
		display: none;
	}

	.image-wrapper {
		display: flex;
		justify-content: center;
		position: relative;
	}
`;

const getTargetImageSize = (originWidth, originHeight, targetWidth, targetHeight) => {
	const fitWidth = targetWidth / originWidth;
	const fitHeight = targetHeight / originHeight;
	const fitFinal = Math.min(fitWidth, fitHeight);
	
	const width = originWidth * fitFinal;
	const height = originHeight * fitFinal;
  
	return { width, height };
};

const ImageMagnify = ({
	image, alt, width, zoomDefault,
	noFullScreen, toggleModal, buttonText,
}) => {
	const containerRef = React.useRef(null);
	const imageRef = React.useRef(null);
	const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });
	const { width: windowWidth, height: windowHeight } = useWindowSize();

	const settingImageSize = () => {
		if (imageRef.current) {
			const containerRect = containerRef.current.getBoundingClientRect();

			const size = getTargetImageSize(
				imageRef.current.naturalWidth,
				imageRef.current.naturalHeight,
				containerRect.width,
				containerRect.height,
			);

			setImageSize(size);
		}
	};

	const handleLoad = () => {
		settingImageSize();
	};

	React.useEffect(() => {
		settingImageSize();
	}, [windowWidth, windowHeight]);

	return (
		<Container ref={containerRef}>
			<img
				className="image-object"
				ref={imageRef}
				src={image}
				alt={alt}
				onLoad={handleLoad}
			/>
			<div className="image-wrapper">
				<ReactImageMagnify
					{...{
						smallImage: {
							src: image,
							alt: alt,
							...(!imageSize.width && !imageSize.height) ? { isFluidWidth: true } : {
								width: imageSize.width,
								height: imageSize.height,
							},
						},
						largeImage: {
							src: image,
							width: 2000,
							height: 2000,
						},
						enlargedImagePosition: zoomDefault
							? zoomDefault
							: width < 768
							? "over"
							: null,
					}}
				/>
				{!noFullScreen && (<Button
					small
					white
					onClick={toggleModal}
					className="is-hidden-mobile"
				>
					<IconLibrary icon="search-plus"></IconLibrary>{" "}
					<span>{buttonText}</span>
				</Button>)}
			</div>
		</Container>
	);
};

export default ImageMagnify;
