import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import styled from "styled-components";
import { Box, Heading, Container } from "../sdk/Layout";
import { Button } from "../sdk/Button";
import { Modal } from "../sdk/Elements";

import ProductDetail from "./ProductDetails2";
import ProductGrid from "./ProductGrid2";
import { Helmet } from "react-helmet";

const ModalExtended = styled(Modal)`
    z-index: 999;
    @media (min-width: 769px) {
        padding-bottom: 20px;
    }
    > button {
        background-color: #f1f1f1;
        z-index: 9999;
        @media (max-width: 550px) {
            right: 10px;
            /* top: 10px; */
            --safe-area-inset-top: env(safe-area-inset-top);

            top: calc(var(--safe-area-inset-top) + 10px);
        }
    }
    @media (min-width: 769px) {
        padding-top: 67px;
    }
    @media (max-width: 965px) and (display-mode: standalone) {
        padding-bottom: 65px;
    }
`;
const ButtonExtended = styled(Button)`
    &:active {
        z-index: 999;
    }
`;
const BoxExtended = styled(Box)`
    /* max-width: 98% !important; */
    max-width: 1200px !important;

    position: relative;
    overflow: auto;
    .icon-button {
        left: 20px;
    }
    @media (max-width: 768px) {
        max-height: 100% !important;
        width: 100% !important;
        border-radius: 0 !important;
        padding-top: 50px;
    }
`;

// App component - represents the whole app
class ModalProduct extends Component {
    state = {
        scrollPosition: 0,
    };

    componentDidMount() {
        // document.addEventListener("keydown", this.escFunction, false);
        this.setState({
            scrollPosition:
                window.pageYOffset || document.documentElement.scrollTop,
        });

        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        setTimeout(() => {
            document.documentElement.scrollTop = this.state.scrollPosition;
        }, 10);
    }

    nothing = (e) => {
        // e.preventDefault();
        e.stopPropagation();
    };

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.onClose();
        }
    };

    onClose = () => {
        this.props.close();
    };

    scrollTo() {
        document.getElementById("related-product").scrollIntoView();
    }

    toggleModalProduct = (slug, product) => {
        document.getElementById("product-modal").scrollTop = 0;
        this.props.toggleModalProduct(product);
    };

    render() {
        const {
            product,
            recomendations,
            addToCart,
            viewItem,
            relatedProducts,
            asociatedProducts,
            jsonSettings,
            defaultQuantity,
            removeItem,
            refetchOnPriceChange,
        } = this.props;
        if (!this.props.visible || product.sku == null) return null;
        const { bodyAttributes } = Helmet.peek();
        return (
            <ModalExtended className={"is-active"} full>
                <Helmet>
                    <body
                        className={
                            bodyAttributes.class &&
                            bodyAttributes.class.indexOf(
                                "ReactModal__Body--open"
                            ) > -1
                                ? bodyAttributes.class
                                : bodyAttributes.class +
                                  " ReactModal__Body--open"
                        }
                    />
                </Helmet>
                <ButtonExtended onClick={this.onClose}></ButtonExtended>
                <span onClick={this.onClose} />
                <BoxExtended id="product-modal">
                    <Container>
                        <ProductDetail
                            product={product}
                            defaultQuantity={defaultQuantity}
                            addToCart={addToCart}
                            removeItem={removeItem}
                            jsonSettings={jsonSettings}
                            crossSell={recomendations}
                            refetch={refetchOnPriceChange}
                            asociatedProducts={asociatedProducts}
                            // nextSlug={nextSlug}
                            // backSlug={backSlug}
                            // retrieveProduct={this.retrieveProduct}
                            // handleNextProduct={this.handleNextProduct}
                            viewItem={this.toggleModalProduct}
                            // handleLastProduct={this.handleLastProduct}
                        />
                        <Heading isCentered>
                            <h3>{relatedProducts}</h3>
                        </Heading>
                        <ProductGrid
                            products={recomendations}
                            grid="x5"
                            padding="0"
                            viewItem={this.toggleModalProduct}
                            addToCart={addToCart}
                        />
                    </Container>
                </BoxExtended>
            </ModalExtended>
        );
    }
}

export default ModalProduct;
