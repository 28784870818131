import React from "react";
import { Gallery, BigImage, Thumbnails, ThumbItem, FullView } from "./style";
import ImageMagnify from "./components/ImageMagnify";
import IconLibrary from "../Icons2";
import WithWidth from "../../helpers/WithWidth";
import { Column, Box } from "../../sdk/Layout";
import FullScreen from "./FullScreen";
import { useTranslation } from "../../../lang";
// import { Button } from "../../sdk/Button";

function GalleryComponent({
    images,
    slug,
    zoomDefault,
    width,
    forceDisplay,
    horizontal,
    videosUrl,
    noFullScreen,
}) {
    const [currentImage, setCurrentImage] = React.useState(images ? images[0].url : "");
    const [_t] = useTranslation();
    const [modal, setModal] = React.useState(false);
    const videoRef = React.useRef();

    const [videoPlaying, setVideoPlaying] = React.useState(null);
    const [videoKey, setVideoKey] = React.useState(0);

    const set = (value) => {
        setCurrentImage(value);
        setVideoPlaying(null);
    };
    const handleVideoPlay = (value) => {
        setVideoPlaying(value);
        setVideoKey((prev) => prev + 1);
        if (analytics)
            analytics.track("Product Video Played", {
                slug,
            });
        setTimeout(() => {
            if (videoRef.current) videoRef.current.play();
        }, 100);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const imagesMap = images
        ? images.map((index, i) => (
              <ThumbItem
                  key={`gallery image ${i}`}
                  current={currentImage === index.url}
                  onMouseEnter={() => set(index.url)}
              >
                  <img src={index.thumb} alt={slug} />
              </ThumbItem>
          ))
        : [];

    if (videosUrl && videosUrl.length > 0) {
        videosUrl.map((index, i) => {
            imagesMap.push(
                <ThumbItem
                    key={`video image ${i}`}
                    // current={currentImage === index.url}
                    onClick={() => handleVideoPlay(index)}
                >
                    <span>
                        <IconLibrary icon="play"></IconLibrary>
                    </span>
                    <img
                        src={images && images.length > 0 ? images[0].thumb : ""}
                        alt={slug}
                    />
                </ThumbItem>
            );
        });
    }
    React.useEffect(() => {
        if (forceDisplay != null && forceDisplay !== "") {
            setCurrentImage(forceDisplay);
        } else {
            setCurrentImage(
                images
                    ? images[0].url
                    : "https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg"
            );
        }
    }, [forceDisplay]);

    React.useEffect(() => {
        if (images ? images[0].url : "") {
            set(images ? images[0].url : "");
        }
    }, [images ? images[0].url : ""]);

    return (
        <>
            <Gallery horizontal={horizontal}>
                <Column>
                    <BigImage>
                        {videoPlaying != null ? (
                            videoPlaying.type === "youtube" ? (
                                <div key={`video-iframe-${videoKey}`}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `<iframe src=${videoPlaying.url} frameborder="0" allow="autoplay; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
                                        }}
                                    />
                                </div>
                            ) : (
                                <div key={`video-control-${videoKey}`}>
                                    <video
                                        ref={videoRef}
                                        width={400}
                                        controls
                                        autoPlay
                                    >
                                        <source
                                            src={videoPlaying.url}
                                            type="video/mp4"
                                            autoPlay
                                        />
                                        Your browser does not support HTML
                                        video.
                                    </video>
                                </div>
                            )
                        ) : (
                            <>
                                {/* <ReactImageMagnify
                                    {...{
                                        imageClassName: "big-image-magnify",
                                        smallImage: {
                                            alt: slug,
                                            isFluidWidth: true,
                                            src: currentImage,
                                        },
                                        largeImage: {
                                            src: currentImage,
                                            width: 2000,
                                            height: 2000,
                                        },
                                        enlargedImagePosition: zoomDefault
                                            ? zoomDefault
                                            : width < 768
                                            ? "over"
                                            : null,
                                    }}
                                /> */}
                                <ImageMagnify
                                    image={currentImage}
                                    alt={slug}
                                    width={width}
                                    zoomDefault={zoomDefault}
                                    noFullScreen={noFullScreen}
                                    toggleModal={toggleModal}
                                    buttonText={_t("clickHereToEnlarge")}
                                />
                                {/* {!noFullScreen && (<Button
                                    small
                                    white
                                    onClick={toggleModal}
                                    className="is-hidden-mobile"
                                >
                                    <IconLibrary icon="search-plus"></IconLibrary>{" "}
                                    <span>{_t("clickHereToEnlarge")}</span>
                                </Button>)} */}
                            </>
                        )}
                    </BigImage>
                </Column>
                <Thumbnails isNarrow>
                    <ul>{imagesMap}</ul>
                </Thumbnails>
            </Gallery>
            <FullScreen
                images={images}
                toggleModal={toggleModal}
                modal={modal}
                currentImage={currentImage}
                set={set}
                alt={slug}
            />
        </>
    );
}

export default WithWidth()(GalleryComponent);
