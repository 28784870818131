import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { trackWindowScroll } from "react-lazy-load-image-component";
import Product, { Item, PreloaderProduct } from "../Product2";
// import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "../../../lang";
import Pagina from "../Pagina";
import useDirectos from "../../../blueberry/hooks/useDirectos";
import useLoggedIn from "../../../blueberry/hooks/useLoggedIn";

export const ItemsContainer = styled.div`
	flex-grow: 1;
	font-size: 0;
	margin-right: -0.5%;
	margin-left: -0.5%;
	padding: ${(props) => (props.padding ? props.padding : "0px 10px")};
	&.x3 ${Item} {
		width: 32.333%;
		@media (max-width: 980px) {
			width: 49%;
			padding: 10px;
		}
	}
	&.x4 ${Item} {
		width: 24%;
		@media (max-width: 980px) {
			width: 49%;
			padding: 10px;
		}
	}
	&.x5 ${Item} {
		width: 19%;
		padding: 20px;
	}
	&.x6 ${Item} {
		padding: 10px;
		width: 15.666%;
	}
	&.x7 ${Item} {
		padding: 10px;
		width: 13.285%;
		h3 {
			font-size: 12px;
		}
		@media (max-width: 980px) {
			width: 24%;
		}
	}
`;

let strings = {
	available: "Disponible",
	notAvailable: "No Disponible",
	fair: "Feria",
	caseOf: "Caja de",
	transit: "Transit",
	inCart: "inCart",
	quickView: "Vista Previa",
	direct: "direct",
};

function ProductGrid(props) {
	const {
		products,
		grid = "x5",
		onQuickView,
		currentPage,
		setPage,
		currentProduct,
		renderQuickView,
		width,
		scrollPosition,
		addToCart,
		viewItem,
		isRetrivingProducts,
		lastKnownProduct,
		removeProduct,
		limit,
		order,
		getInAdvancePage,
		padding,
		lazyLoadAll,
		hideAddToCart,
	} = props;

	const [_t] = useTranslation();
	// console.log("rendered product grid");
	const refRequest = React.useRef(false);
	const isDirectos = useDirectos();
	const isLoggedIn = useLoggedIn();

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	React.useEffect(() => {
		Object.keys(strings).map((index) => (strings[index] = _t(index)));
	}, []);

	React.useEffect(() => {
		if (inView && !refRequest.current && getInAdvancePage != null) {
			getInAdvancePage(currentPage + 1);
			refRequest.current = true;
		}
	}, [inView]);

	if (
		isRetrivingProducts &&
		(products == null ||
			products.products == null ||
			products.products.length === 0)
	) {
		return (
			<ItemsContainer padding={padding} className={grid}>
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
				<PreloaderProduct />
			</ItemsContainer>
		);
	}

	if (products == null || products.products == null) {
		return null;
	}

	return (
		<ItemsContainer className={grid} padding={padding}>
			{products.products.map((index, i) => {
				if (renderQuickView && currentProduct === i && width < 769) {
					return renderQuickView();
				}

				return (
					<Product
						key={`${index._id}-${isLoggedIn.toString()}`}
						active={currentProduct === i}
						disableLazyLoad={lazyLoadAll ? false : i < 8}
						product={index}
						strings={strings}
						isLoggedIn={isLoggedIn}
						removeProduct={removeProduct}
						hideAddToCart={hideAddToCart}
						addToCart={addToCart}
						isDirectos={isDirectos}
						viewItem={viewItem}
						highlight={index.slug === lastKnownProduct}
						index={i}
						scrollPosition={scrollPosition}
						onQuickView={onQuickView}
					/>
				);
			})}
			<Pagina
				pages={products.pages}
				count={products.count}
				currentPage={currentPage}
				setPage={setPage}
				order={order}
				limit={limit}
				ref={ref}
			/>
		</ItemsContainer>
	);
}

ProductGrid.propTypes = {
	viewItem: PropTypes.func.isRequired,
	renderQuickView: PropTypes.func,
	addToCart: PropTypes.func.isRequired,
	products: PropTypes.object.isRequired,
	grid: PropTypes.string.isRequired,
	isRetrivingProducts: PropTypes.bool,
	removeProduct: PropTypes.string,
	currentProduct: PropTypes.number,
	limit: PropTypes.number,
	lastKnownProduct: PropTypes.string,
};

export default trackWindowScroll(ProductGrid);
